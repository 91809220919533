import React from "react";
import { Container, Button, Img, Flag } from "./styles";
import { CreateRequest } from "../create-request";
import candleOff from "../../assets/candle-off.png";
import candleOn from "../../assets/candle-on.gif";
import flag from "../../assets/ecuador_flag.png";
import Modal from "react-modal";
import music from "../../assets/musica.mp3";

const customStyles = {
  content: {
    height: "300px",
    width: "600px",
    margin: "auto",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "#ffffff30",
  },
};

export const Candle = () => {
  const [candle, setCandle] = React.useState(false);
  const [creatingRequest, setCreatingRequest] = React.useState(false);

  const handleCandleOn = () => {
    setCreatingRequest(true);
  };

  const handleCloseModal = () => {
    setCreatingRequest(false);
    setCandle(true);
    const audio = new Audio(music);
    audio.volume = 0.1;
    audio.play();
  };

  return (
    <Container>
      <Modal
        isOpen={creatingRequest}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <CreateRequest closeModal={handleCloseModal} />
      </Modal>
      <div>
        <Img src={candle ? candleOn : candleOff} />
        <Flag style={{height: '300px', paddingLeft: '50px'}} src={flag} />
      </div>
      {!candle && (
        <Button onClick={handleCandleOn}>🙏 Acender vela com fé 🙏</Button>
      )}
    </Container>
  );
};
